<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="养老机构编号" prop="agId">
            <el-input v-model="form.agId" placeholder="" auto-complete="off" :disabled="true">
              <el-button slot="append" icon="el-icon-search" @click="selectPersonId()"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
        <el-form-item label="养老机构名称" prop="agName">
            <el-input v-model="form.agName" placeholder="" auto-complete="off" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="楼层编号" prop="floorNum">
            <el-input v-model="form.floorNum" placeholder="" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="房间编号" prop="roomNum">
            <el-input v-model="form.roomNum" placeholder="" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="床位编号" prop="bedNum">
            <el-input v-model="form.bedNum" placeholder="" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="启用状态" prop="acvite">
            <el-select v-model="form.acvite" placeholder="请选择启用状态">
              <el-option
                v-for="item in bedStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="床位名称" prop="bedTitle">
            <el-input v-model="form.bedTitle" placeholder="" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>


      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="床位描述" prop="bedDesc">
            <el-input v-model="form.bedDesc"  :rows="5" type="textarea" maxlength="1000" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="床位拍照" prop="bedImg">
            <upload-img :limit="1" :uploadLists="bedImgList" @uploadChange="uploadSuccess1"></upload-img>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="选择养老机构" :visible.sync="visible" width="60%" :close-on-click-modal="false">
      <div>
         <vxe-form title-align="right" title-width="120" title-colon :data="searchData" @submit="search" @reset="searchReset">
        <vxe-form-item title="机构名称" span="12">
          <vxe-input v-model="searchData.orgName" placeholder="请输入机构名称" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item align="center" span="12">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">搜索</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
        <div style="margin-top:10px;">
          <el-table :data="tableData" ref="singleTable" highlight-current-row border size="medium" :height="height">
            <el-table-column label="" width="40">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.$index"
                  v-model="radio"
                  @change.native="getCurrentRow(scope.row)"
                  style="color: #fff;padding-left: 10px; "
                ></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="orgName" label="机构名称" width="280" align="center"></el-table-column>
            <el-table-column prop="address" label="机构地址" width="560" align="center"></el-table-column>
            <el-table-column prop="mainPerson" label="负责人姓名" width="100" align="center"></el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px;">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="tablePage.currentPage"
              :page-sizes="[50, 100, 500]"
              :page-size="tablePage.pageSize"
              layout="total,jumper,prev, pager, next,sizes"
              :total="tablePage.totalResult"
            ></el-pagination>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="cancel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
import api from '@/store/API/api'
import axios from 'axios'

export default {
  name: 'AddOrEdit',
  components: {
    UploadImg,
  },
  data() {
    return {
      titleName: '',
      radio: '',
      height: 240,
      searchData: {
        orgName: '',
      },
      visible: false,
      tableData: [],
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 5,
        align: 'left',
        pageSizes: [5, 10, 20, 50, 100, 500, 1000],
        perfect: true,
      },

      bedImgList: [],
      bedStatusList: [
        {
          value: '1',
          label: '启用',
        },
        {
          value: '0',
          label: '禁用',
        },
      ],
      form: {},
      rules: {
        agId: [{ required: true, message: '请选择机构编号', trigger: 'blur' }],
        floorNum: [{ required: true, message: '请输入楼层编号', trigger: 'blur' }],
        roomNum: [{ required: true, message: '请输入房间编号', trigger: 'blur' }],
        bedNum: [{ required: true, message: '请输入床位编号', trigger: 'blur' }],
        bedTitle: [{ required: true, message: '请输入床位名称', trigger: 'blur' }],
        acvite: [{ required: true, message: '请输入床位启用状态', trigger: 'blur' }],
      },
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['saveOrgBedInfo', 'detailOrgBedInfo','getmechanismCareListServe']),
    // getCurrentRow(row) {
    //   this.form.serviceSiteId = row.id
    // },
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    initView() {
      this.getDetail()
    },
    async getDetail() {
      if (this.routeType == 'add') {
        this.titleName = '添加床位信息'
      } else {
        this.titleName = '修改床位信息'
        this.itemData = JSON.parse(this.$route.query.item)
        //这里调用请求编辑时的详情接口渲染form表单数据

        let params = {
          id: this.itemData.id,
        }
        let data = null
        let server = 'detailOrgBedInfo'
        await this[server](params).then((res) => {
          if (res.code == 200) {
            data = res.data
            this.form = {
              id: data.id,
              agId: data.agId,
              agName: data.orgName,
              floorNum: data.floorNum,
              roomNum: data.roomNum,
              bedNum: data.bedNum,
              bedNum: data.bedNum,
              acvite: data.acvite,
              bedTitle: data.bedTitle,
              bedDesc: data.bedDesc,
              bedImg: data.bedImg,
            }
          }
        })
        if (this.form.bedImg) {
          this.getFileDetail(this.form.bedImg)
        }
        // if (this.form.businessLicense) {
        //   this.getFileDetail1(this.form.businessLicense)
        // }
        // if (this.form.professionalQualification) {
        //   this.getFileDetail2(this.form.professionalQualification)
        // }
      }
    },
    uploadSuccess1(data) {
      this.form.bedImg = data.join(',')
    },
    getFileDetail(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            Authorization: sessionStorage.token,
          },
        }).then((res) => {
          this.bedImgList.push({
            id: item,
            url: window.URL.createObjectURL(res.data),
          })
        })
      })
    },
    submitEvent(formName) {
      // if (!this.form.qualificationImg) {
      //   this.$XModal.message({
      //     message: '请上传施工资质图片',
      //     status: 'warning',
      //   })
      //   return
      // }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          let server = 'saveOrgBedInfo'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success',
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    selectPersonId() {
      this.visible = true
    },
    searchReset() {
      this.searchData = {}
      this.getConsConsultant()
    },
    getCurrentRow(row) {
      this.form.agId = row.id
      this.form.agName = row.orgName
    },
    cancel() {
      this.visible = false
    },
    search() {
      this.getConsConsultant()
    },

    handleSizeChange: function(size) {
      this.tablePage.pageSize = size
      this.getConsConsultant()
    },
    // 控制页面的切换
    handleCurrentChange: function(currentPage) {
      this.tablePage.currentPage = currentPage
      this.getConsConsultant()
    },
    getConsConsultant() {
      let params = {
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          orgName: this.searchData.orgName,
        },
      }
      let data = null
      let server = 'getmechanismCareListServe'
      this[server](params).then((res) => {
        if (res.code == 200) {
          console.log(res)
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .dialog-footer {
    text-align: center;
  }
}
</style>
